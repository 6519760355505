<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="!isLoading && !isError" class="form-detail" style="margin-left: 10%">

  <div class="title-form" style="padding-top: 5%">
    <div class="f20 bottom20 flex1 bold">Assign route to Carrier</div>
  </div>

  <div class="current-assigned-selection" *ngIf="isAssignedCarrier">
    <div *ngFor="let key of ['currentCarrier']">
      <div class="form-label-v2 bold bottom5">{{ getLabel(key) }}</div>
      <div class="info-carrier">
        <div class="content">
          <div class="medium bottom10">{{ job.carrierCost || 0}} - {{ job.carrier?.basicInfo?.name || "N/A" }}</div>
          <div class="assign-text"> assigned by {{ job.carrierSalesRep?.fullName || "N/A" }}
            at {{ this.infoAssignedCarrier.timeAssigned }}</div>
        </div>
      </div>
    </div>

  </div>

  <div class="new-carrier-selection" style="margin-top: 5%">
    <div *ngFor="let key of ['newCarrier']">
      <div class="form-label-v2 bold bottom5">{{ getLabel(key) }}</div>
      <div class="info-carrier">
        <div class="content">
          <span class="medium" style="padding-bottom: 2%">${{ bestOffer.price || 0}}
            - {{ bestOffer.carrier.basicInfo.name || "" }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div form-footer [onProgress]="onProgress"
     [nzIconCancel]="null" [nzIconOK]="null"
     [canClickOK]="!onProgress && !isLoading"
     [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
     (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
