<form [formGroup]="formInput" nz-form>
  <div class="form-inner-box">
    <ng-container *ngFor="let key of formInputKeys">
      <div class="form-item" [ngClass]="key">
        <div class="form-label-v2">
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <ng-container [ngSwitch]="key">
          <div *ngSwitchCase="'tz'">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" style="width: 100%;"
                  [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key" 
                  nzDropdownClassName="timezone"
                  (ngModelChange)="onChange($event, key)">
                  <nz-option *ngFor="let timezone of listTimezones" [nzLabel]="timezone" [nzValue]="timezone"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngSwitchCase="'fromDropDate'">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker
                  [formControlName]="key" nzFormat="yyyy-MM-dd" 
                  (ngModelChange)="onChange($event, key)">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngSwitchCase="'toDropDate'">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker
                  [formControlName]="key" nzFormat="yyyy-MM-dd" 
                  (ngModelChange)="onChange($event, key)">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="form-item">
      <div class="form-label-v2"></div>
      <button nz-button type="button" (click)="onBtnExport()" [nzLoading]="isExporting">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        Export
      </button>
    </div>
  </div>
</form>
  
  