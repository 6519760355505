import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DateUtil } from '@services/date-utils';
import { Log } from '@services/log';
import { MasterData } from '@services/master.data';
import { startOfDay, endOfDay } from 'date-fns';
import { Utils } from '@services/utils';
import { StringULID } from '@wearewarp/types';


export interface FormDataFilter {
  status?: string,
  clientId?: StringULID,
  shipmentType?: string,
  carrierId?: StringULID,
  pickupState?: string,
  dropoffState?: string,
  vehicleType?: string,
  fromDate?: string,
  toDate?: string,
  picId?: StringULID,
  carrierSaleRepId?: StringULID,
  clientSaleRepId?: StringULID,
}

@Component({
  selector: 'filter-modal',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FilterModal extends BaseFormDialog1<FormDataFilter> {

  constructor() {
    super();
   }
   protected formGroupDeclaration: FormGroupDeclaration = {
      status: { label: "Status", placeHolder: "Select", notAcceptEmpty: true },
      clientId: { label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select' },
      shipmentType: { label: 'Shipment Type', placeHolder: 'Select', notAcceptEmpty: true },
      carrierId: { label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select' },
      pickupState: { label: 'Pickup State', placeHolder: 'Select Pick', notAcceptEmpty: true },
      dropoffState: { label: 'Delivery State', placeHolder: 'Select Drop', notAcceptEmpty: true },
      // equipment: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      vehicleType: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      fromDate: { label: 'From date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
      toDate: { label: 'To date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
      picId: { label: 'Dispatcher', placeHolder: 'Select', notAcceptEmpty: true },
      carrierSaleRepId: { label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientSaleRepId: { label: 'Client Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientFilterType: { label: 'Client Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
   }
   get formInputKeys(): Array<string> {
    return this._formInputKeys;
  }

  @Input() dataForm: any;
  @Input() currentTap: number;
  @Input() urlMyLoad: any;
  @Input() page: any;
  @Input() search: any;
  @Input() lastUpdated: any;
  @Input() lastUpdatedTime: any;
  @Input() requestId: any;
  @Input() onSave: (data: FormDataFilter, previewInfo: string[]) => void;

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchListCarriers();
    this.fetchListDispatchers();
    this.fetchListCarrierSales();
    this.fetchListClientSales();
    this.setFormValues(this.dataForm);
  }

  get isTabAll() {
    return this.currentTap == 4;
  }

  public listClients = [];
  public listCarriers = [];
  public listCarrierSales = [];
  public listClientSales = [];
  public listDispatchers = [];
  public isFetchingCarrier = false;
  public isFetchingCarrierSaleRep = false;
  listDispatcherForDropdown: any[] = [];
  dispatchersHaveJobs = [];
  public countriesStates = MasterData.getCountriesStates_forSelectGroup();
  public listShipmentTypes = Const.ShipmentTypesArray;
  public showDispatcher = false;
  private fetchListCarriers() {
    let url = `${Const.APIURI_CARRIERS}/list/all_for_filter`;
    this.isFetchingCarrier = true;
    this.api.GET(url).subscribe(
      (resp) => {
        Log.d("fetchListData done ", resp);
        this.listCarriers = resp.data.list_data;
        this.isFetchingCarrier = false;
      },
      (err) => {
        this.showErr(err);
        this.isFetchingCarrier = false;
      }
    );
  }

  public isFetchingClientSaleRep = false;
  private fetchListClientSales(silent: boolean = false) {
    this.isFetchingClientSaleRep = true;
    this.api.getListClientSalesRepUsersFilter().subscribe(
      resp => {
        this.listClientSales = resp?.data?.list_data ?? [];
        this.isFetchingClientSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingClientSaleRep = false;
      }
    );
  }
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }
  public getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? "";
  }

  public getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? "";
  }
  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || '';
  }
  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }
  public getCarrierNameWithMCandDOT(carrier): string {
    if (carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
      return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`;
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`;
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`;
    return carrier?.basicInfo?.name ?? "";
  }
  public getListDispatcherForDropdown() {
    let list = [...this.listDispatchers];
    return list.map(dispatcher => {
      const item = this.dispatchersHaveJobs.find(i => i._id == dispatcher.id);
      dispatcher.jobCount = item?.jobCount || 0;
      return dispatcher;
    }).filter(dispatcher => dispatcher.jobCount);
  }
  public dispatcherName(item) {
    let name: string = this.getFullName(item);
    if (item.jobCount) {
      name = `${name} (${item.jobCount})`;
    }
    return name;
  }

  getVehicleType() {
    const vehicleType = this.getItemValue('vehicleType')
    if (vehicleType) {
      return { code: vehicleType}
    }
    return null;
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = this.getFormData_JSON(true);
    if (condition.status && !Utils.isArrayNotEmpty(condition.status)) {
      delete condition.status;
    }
    if (this.needPODChecked) {
      condition.isNeedPOD = true;
    }
    if (condition.sort) {
      q["sort"] = condition.sort;
      delete condition.sort;
    }
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q["filter"] = f;
    }
    q["tab"] = Utils.parseQueryStringFromUrl(this.router.url)?.tab || Const.DispatchTab.all_load;
    return q;
  }

  public getListDispatcherHaveJobAfterFilter() {
    this.listDispatcherForDropdown = this.listDispatcherForDropdown.map((dispatcher) => {
      dispatcher.jobCount = 0;
      return dispatcher;
    });
    const query = this.buildQuery();
    this.api.POST(`${Const.APIURI_JOBS}/count_job_for_dispatcher_list`, query).subscribe(
      resp => {
        this.dispatchersHaveJobs = resp.data.list_data;
        setTimeout(() => {
          this.listDispatcherForDropdown = this.getListDispatcherForDropdown();
        }, 100);
      }, err => {
        Log.e(err);
      }
    );
  }
  public isFetchingDispatcher = false;
  private fetchListDispatchers(silent: boolean = false) {
    this.isFetchingDispatcher = true;
    this.api.getListDispatcherUsersFilter().subscribe(
      resp => {
        this.listDispatchers = resp?.data?.list_data ?? [];
        this.isFetchingDispatcher = false;
        this.getListDispatcherHaveJobAfterFilter();
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingDispatcher = false;
      }
    );
  }

  onChange(event, key) {
    if (key == 'vehicleType') {
      this.setItemValue(key, event?.code);
    }
    this.fetchListDispatchers();
  }

  onChangeCarrier(value, key){
    if(value != 'no') {
      this.needCarrierChecked = false;
    }
    if(value == 'no') {
      this.needCarrierChecked = true;
      this.needPODChecked = false;
    }
  }

  onChangeDispatcher(value, key){
    if(value != 'no') {
      this.needDispatcherChecked = false;
    }
    if(value == 'no') {
      this.needDispatcherChecked = true;
      this.needPODChecked = false;
    }
  }

  // "Need Dispatcher" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Dispatcher: has not assigned yet
  public needDispatcherChecked = false;
  onCheckboxNeedDispatcher(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.setItemValue('picId', 'no')
    } else {
      this.setItemValue('picId', null)
    }
  }

  // "Need Carrier" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Carrier: has not assigned yet
  public needCarrierChecked = false;
  onCheckboxNeedCarrier(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.setItemValue('carrierId', 'no')
    } else {
      this.setItemValue('carrierId', null)
    }
  }

  public needPODChecked = false;
  onCheckboxNeedPOD(event) {
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    if(event == true) {
      if(this.getFormItemValue('picId') == "no") {
        this.setItemValue('picId', null)
      }
      if(this.getFormItemValue('carrierId') == "no") {
        this.setItemValue('carrierId', null)
      }
    }
  }
  clearFilter() {
    for(let key of this.formInputKeys) {
      this.setItemValue(key, null);
    }
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    this.needPODChecked = false;
  }
  // reset form filter
  onBtnResetFilter() {
    this.clearFilter();
    this.onResetFilter();
  }

  public returnDataForm: (dataFormJson, dataCheckBox) => void = () => { }
  public onResetFilter:() => void = () => {}

  onBtnFilters() {
    const data: FormDataFilter = this.getFormData_JSON(true)
    const previewInfo = this.buildPreviewInfo(data);
    this.onSave(data, previewInfo);
    this.closeDialog();
  }

  private getDescForValue(value: any, key: string) {
    switch (key) {
      case 'clientId': return this.listClients.filter(it => it.id == value)[0]?.name;
      case 'carrierId': return this.listCarriers.filter(it => it.id == value)[0]?.basicInfo?.name;
      case 'carrierSaleRepId': return this.getFullName(this.listCarrierSales.filter(it => it.id == value)[0]);
      case 'clientSaleRepId': return this.getFullName(this.listClientSales.filter(it => it._id == value)[0]);
      case 'fromDate':
      case 'toDate':
        return DateUtil.format(value, 'YYYY-MM-DD');
      default: return value;
    }
  }

  private buildPreviewInfo(data: FormDataFilter): string[] {
    const info: string[] = [];
    for (let key of Object.keys(data)) {
      const label = this.getLabel(key);
      const value = this.getDescForValue(this.formInput.get(key).value, key);
      info.push(`${label}: ${value}`);
    }
    return info;
  }

  setShowDispatcher() {
    if(this.urlMyLoad == 'my_load') {
      return 'my-load'
    } else {
      return 'all-load'
    }
  }
}
