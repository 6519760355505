import { Component, EventEmitter, TemplateRef, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { getDashboard } from "@services/index";
import { Utils } from "@services/utils";
import { BaseList } from "../base/list";
import { SendDocsComponent } from "./send-docs/send-docs.component";
import { DrawerService } from "@app/drawers/drawer.service";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { AddTargetRate } from "./target-rate";
import { Log } from "@services/log";
import { WarpId } from "@wearewarp/universal-libs";
import { SeeAllOrder } from "../components/see-all-orders/see-all-orders";
import { AssignCarrierWithCost } from "../dispatch/components/assign-carrier-with-cost";
import { FilterModal, FormDataFilter } from "./list-header-filter-v4/filter-modal";
import { AssignPic } from "../dispatch/components/assign-pic";
import { AddCarrierSaleRep } from "../dispatch/components/sales/add-carrier-sale-rep";
import { CreateManualLoadContainer } from "../manual-load/create-load";
import { NzModalRef } from "ng-zorro-antd/modal";
import { BizUtil } from "@services/biz";
import { Const as WarpConst } from '@wearewarp/universal-libs';
import {AssignCarrier} from "@app/admin/carrier-sales-v2/components/assign-carrier";
import { environment } from "@env/environment";

interface TabData {
  key: string, name: string, query: any, total: number
}
@Component({
  selector: 'carrier-sales-v2',
  templateUrl: './list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class CarrierSales extends BaseList {
  tabIndex = -1;
  public selectedItem = null
  public qParams: any
  private subRefresh;
  private itemIds: string[] = [];
  tabs: TabData[] = [
    {
      key: 'bid_not_sent',
      name: 'Bid Not Sent',
      query: {},
      total: 0,
    },
    {
      key: 'bid_sent_no_response',
      name: 'Bid sent - No Response',
      query: {},
      total: 0,
    },
    {
      key: 'response_received',
      name: 'Response Received',
      query: {},
      total: 0,
    },
    {
      key: 'assigned_route',
      name: 'Assigned Route',
      query: {},
      total: 0,
    },
     {
      key: 'not_accept_load_tender',
      name: 'Not Accept Load Tender',
      query: {},
      total: 0,
    },
    {
      key: 'all',
      name: 'All',
      query: {},
      total: 0,
    }
  ];
  public isLoadingGhostShipment: boolean = true;

  // Dùng cho ReportRevenue
  public get isReportRevenue(): boolean { return false }
  public get canSendDocs(): boolean { return !this.isAdminReadOnlyRole }
  public get canPerformAction(): boolean { return true }
  public get shouldShowTable(): boolean { return true }
  public get revenueSum(): any { return {} }
  onTabChange(event) {
    let tab = this.getTabData(event.index)
    const q: any = { bidStage: tab?.key, page: 1 }
    const search = this.queryParams.search ?? '';
    if (search) {
      q.search = search;
    }
    let currentFilter = JSON.parse(this.queryParams.filter);
    currentFilter.bidStage = tab?.key;
    q['filter'] = JSON.stringify(currentFilter);
    // q['filter'] = JSON.stringify({ bidStage: tab?.key, status: 'created' })
    this.routeWithQueryUrl(q, true);
  }

  private getTabData(index: number): TabData {
    if (index >= 0 && index < this.tabs.length) {
      return this.tabs[index];
    }
    return this.tabs[0];
  }
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  ngAfterContentInit(): void {
  }

  getDefaultFilter = () => {
    // Filter mặc định là "Need Carrier"
    return { bidStage: 'bid_not_sent', status: 'created' }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const { bidStage } = this.queryParams;
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].key === bidStage) {
        this.tabIndex = index
      }
    }

    if (!bidStage) {
      return this.routeWithQueryUrl({ bidStage: this.tabs[0].key });
    }

    if (!this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());
      return this.routeWithQueryUrl({ filter });
    }
    //nếu đổi filter thì reset lại list job
    if (JSON.stringify(prevQueryParam?.filter) != JSON.stringify(this.queryParams.filter)) {
      this.paginationData.reset()
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  onActivate(componentRef): void {
    componentRef?.activatedRoute?.params.subscribe(params => {
      this.selectedItem = params['id'];
      this.qParams = params;
    })
    let refreshListRoutes = componentRef?.refreshListRoutes;
    if (refreshListRoutes instanceof EventEmitter) {
      this.subRefresh = refreshListRoutes.subscribe(e => {
        this.onBtnRefresh();
      })
    }
  }

  onDeactivate(componentRef): void {
    this.subRefresh?.unsubscribe();
    this.selectedItem = null
  }

  loadData(query) {
    // dùng cho ReportRevenue
  }

  toggleTable() {
    // dùng cho ReportRevenue
  }

  public get queryParams() {
    return super.queryParams
  }

  protected getApiUrl(): string {
    return Const.APIURI_JOBS;
  }

  protected onGetDataSucceeded(resp) {
    this.tabs = this.tabs.map(it => {
      it.total = resp?.data?.summary?.[it.key] || 0;
      return it
    });
    this.itemIds = [];
    let marketPlaceLoadIds = [];
    for (let item of this.listData) {
      this.itemIds.push(item.id);
      this.processItem(item);
      if(this.isMarketPlaceLoad(item)) marketPlaceLoadIds.push(item.id);
    }
    if(marketPlaceLoadIds.length){
      this.getGhostShipmentInfo(marketPlaceLoadIds);
    }
  }
  getGhostShipmentInfo(marketPlaceLoadIds: string[]) {
    this.isLoadingGhostShipment = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_MARKETPLACE)}/get_packaging_by_ids`, {ids: marketPlaceLoadIds}).subscribe(
      res => {
        for(let item of this.listData){
          if(marketPlaceLoadIds.includes(item.id)){
            let totalShipmentsItems = res.data.list_data.filter(it => (it.jobId === item._id))[0]?.totalShipmentsItems;
            item.totalShipmentsItems = totalShipmentsItems
          }
        }
        this.isLoadingGhostShipment = false;
      }
    )
  }

  protected processItem(item) {
    item.shipmentLinks = item.shipments?.map(item => {
      return {
        warpId: item.warpId,
        id: item.id,
        orderId: item.orderId
      }
    })

    item.onHold = item.shipments.filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).length > 0 || (item.tags?.length && item.tags.indexOf('HOLD') >= 0)
    item.isGhostLoad = item.type === WarpConst.JobType.ghost;

    item.pickWindow = this.displayWindow(Object.assign({ time: item.earliest_time, timeTo: item.latest_time }, item.pickDt));
    if (!item.dropDt) {
      item.dropDt = this.getDropDate(item.shipments);
      this.api.PUT(`${Const.APIURI_JOBS}/${item._id}/update_order_ralated_fields`, {}).subscribe(
        resp => {
        }, err => {
          // this.showErr(err);
          Log.e(err);
        }
      );
    }
    item.dropWindow = this.displayWindow(item.dropDt);
    item.isShowOnBidBoard = this.isShowOnBidBoard(item);
    if (this.canPerformAction) {
      const params = { canPerformAction: true };
      this.updateDatRate(item, params);
    }
    item.picName = this.getFullName(item?.pic) || null
    item.avgPastRates = item.avgPastRates ? InputHelper.formatMoney2(item.avgPastRates.toFixed(2).toString()) : 'N/A';
  }

  getLabelGhostLoad(item) {
    return item.isGhostLoad && item.source == WarpConst.JobSources.marketplace ? 'Marketplace' : 'Ghost Load';
  }

  hasBid(item): boolean {
    return (item.carrierBids ?? []).length > 0;
  }

  onReUpdateDatRate(item, event) {
    this.updateDatRate(item, {});
    // if (event.ctrlKey || event.metaKey) {
    //   this.updateDatRate(item, {});
    // }
  }

  private updateDatRate(item, params) {
    item.isLoading = true;
    this.api.PUT(`${Const.APIURI_JOBS}/${item._id}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge) {
          const index = this.listData.findIndex(it => it._id == item._id);
          if (index >= 0) {
            this.listData[index].datRate = resp?.data;
          }
        }
        item.isLoading = false;
      }, err => {
        // this.showErr(err);
        item.isLoading = false;
        Log.e(err);
      }
    );
  }

  onReUpdateAvgPastRate(item) {
    this.updateAvgPastRate(item, {});
  }

  private updateAvgPastRate(item, params) {
    item.isLoadingAvg = true;
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/${item.id}/update_avg_past_rate`), params).subscribe(
      resp => {
        if (resp?.data) {
          const index = this.listData.findIndex(it => it._id == item._id);
          if (index >= 0) {
            this.listData[index].avgPastRates = InputHelper.formatMoney2(resp?.data.toFixed(2).toString());
          }
        }
        item.isLoadingAvg = false;
      }, err => {
        item.isLoadingAvg = false;
        Log.e(err);
      }
    );
  }

  // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
  private displayWindow(obj: { time: string, timeTo: string, timezone: string, isAppointment: boolean }) {
    if (!obj || (!obj.time && !obj.timeTo)) return 'N/A';
    let formatDateTime = 'ddd, MMM D . h:mm a';
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split('.');
    let arr2 = timeTo.split('.');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom} - ${timeTo}`;
    if (obj.isAppointment) {
      str += '\n(Appointment Scheduled)';
    }
    return str;
  }

  public getRouterLink(job, index) {
    return [this.routeAdminShipmentList, job.shipmentLinks[index].orderId]
  }

  public getRouterFragment(job, index) {
    let item = job.shipmentLinks[index];
    if (item.id) {
      return `${item.id}`;
    }
    return undefined;
  }

  public getCarrierShortName(name: string) {
    if (name.length > 14) {
      return name.substring(0, 14) + '...';
    }
    return name;
  }

  public refresh() {
    this.routeWithQueryUrl(Object.assign({}, this.qParams, { loaded: Date.now() }))
  }

  public onBtnCarrier(index: number) {
    if (this.isAdminReadOnlyRole) return;
    let job = this.listData[index];
    DialogService.openFormDialog1(AssignCarrierWithCost, {
      nzComponentParams: {
        jobId: job._id,
        carrierId: job.carrier?._id,
        shipments: job.shipments,
        totalShipmentCost: InputHelper.getValueMoney(job['totalShipmentsCost']),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.listData[index].carrier = resp.data?.assignedCarrier?.carrierObj;
          this.refresh();
          let msg = 'Carrier has been assigned successfully.<br>Load tender is being generated, please wait a few seconds then refresh the page to see/download it.';
          if (Utils.isStringNotEmpty(resp)) {
            msg = resp;
          }
          this.showDialog(msg, () => this.onBtnRefresh())
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  public onBtnSendDocs(index: number) {
    if (this.isAdminReadOnlyRole) return;
    let job = this.listData[index];
    if (!job.carrier) return;
    DialogService.openFormDialog1(SendDocsComponent, {
      nzComponentParams: {
        job: job,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
        },
      },
      nzClassName: "send-docs-form",
    });
  }
  public onBtnOnUpdateTargetRate(item) {
    DialogService.openFormDialog1(AddTargetRate, {
      nzComponentParams: {
        data: item?.datRate?.cost_update?.cost,
        closeOnSuccess: true,
        onSave: data => this.updateDatRate(item, data)
      },
    });
  }

  shouldShowEquipmentAndTemp(job) {
    return job.equipments || job.tempRange;
  }

  public shouldShowClient(item): boolean {
    return this.isAdmin && Utils.isArrayNotEmpty(item.clients);
  }

  public seeAllOrder(jobIndex) {
    let job = this.listData[jobIndex];
    DrawerService.openFormDrawer(SeeAllOrder, {
      nzContentParams: {
        job: job,
      },
    });
  }

  public onClickCreateCarrierBid(item) {
    if (this.isAdminReadOnlyRole) return;
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/createByJob`, { jobId: item?._id }).subscribe(
      resp => {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/${this.routeAdminCarrierSales}/${resp?.data?.id}`])
        );
        this.router.navigate([url]);
      }, err => {
        this.showErr(err);
      }
    );
  }

  public isShowOnBidBoard(item) {
    if (!Utils.isArrayNotEmpty(item.carrierBids)) return 'No';
    const lastBid = item.carrierBids[item.carrierBids.length - 1];
    return lastBid?.isShowOnBidBoard ? 'Yes' : 'No';
  }

  public onClickViewCarrierBid(item) {
    const lastBid = (item.carrierBids || []).pop();
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.routeAdminCarrierSales}/${lastBid?.id}`])
    );
    this.router.navigate([url]);
  }

  public getBestOffers(item) {
    if (item.carrierBidInfo?.bestOffer) {
      let bestOffers = item.carrierBidInfo.bestOffer;
      if (item.assignedCarrier?.carrierId) {
        for (let i = 0; i < bestOffers.length; i++) {
          bestOffers[i].flagAssigned = bestOffers[i].carrierId == item.assignedCarrier.carrierId;
        }
      }
      return bestOffers;
    } else {
      return [];
    }
  }

  public getAcceptanceCount(item) {
    return item.carrierBidInfo?.acceptanceCount || 0;
  }
  public getRefuseCount(item) {
    return item.carrierBidInfo?.refuseCount || 0;
  }
  public getMatchingCount(item) {
    return item.carrierBidInfo?.waitingCount + this.getRefuseCount(item) + this.getAcceptanceCount(item) || 0;
  }

  public getSentCount(item) {
    return item.carrierBidInfo?.sendCount || 0;
  }

  public formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  private getDeliveryInfoWindows(info) {
    if (Utils.isArrayNotEmpty(info.windows)) {
      return info.windows;
    } else if (info.appointmentInfo) {
      return [{ ...info.appointmentInfo, isAppointment: true }];
    }
    return [];
  }

  private handleDeliveryDate(info, arr) {
    let windows = this.getDeliveryInfoWindows(info);
    if (Utils.isArrayNotEmpty(windows)) {
      let timezone = ((info.addr || {}).metadata || {}).timeZoneStandard;
      for (let item of windows) {
        arr.push({ time: item.from, timeTo: item.to, timezone, isAppointment: item.isAppointment });
      }
    }
  }
  private getDropDate(shipments) {
    let arr = [];
    if (Utils.isArrayNotEmpty(shipments)) {
      for (let order of shipments) {
        if (order.deliveryInfos) {
          for (let info of order.deliveryInfos) {
            if (info.type == Const.TaskType.DROPOFF) {
              this.handleDeliveryDate(info, arr);
            }
          }
        } else {
          // deprecated
          if (Utils.isArrayNotEmpty(order.dropInfo.windows)) {
            let timezone = ((order.dropInfo.addr || {}).metadata || {}).timeZoneStandard;
            for (let item of order.dropInfo.windows) {
              arr.push({ time: item.from, timeTo: item.to, timezone });
            }
          }
        }
      }
    }
    arr.sort((a, b) => a.time < b.time ? 1 : (a.time > b.time ? -1 : 0));
    if (arr.length > 0) {
      return arr[0];
    } else {
      return null;
    }
  }
  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  getInfo(item, type) {
    let task;
    if (type == Const.TaskType.PICKUP) {//pickup đầu tiên
      task = item.tasks.filter(t => t.type == Const.TaskType.PICKUP)[0];
    }
    else {//dropoff cuối cùng
      task = item.tasks.slice().reverse().filter(t => t.type == Const.TaskType.DROPOFF)[0];
    }
    return task?.info
  }

  public getFirstShipmentId(shipments = []) {
    if (!shipments || !shipments?.length) {
      return ''
    }
    let shipmentWarpIds = shipments.map(item => this.showShipmentCode(item)).filter(item => !!item)
    if (shipmentWarpIds.length == 0) return ''
    shipmentWarpIds.sort()
    return shipmentWarpIds[0]
  }

  public getFirstClientName(item) {
    return item.clients[0].name;
  }

  public getAllClientNames(item) {
    return item.clients.map(client => client.name).join(', ');
  }

  public getFirstCommodity(item) {
    if (item.totalShipmentsItems?.commodity?.length > 1)
      return `${item.totalShipmentsItems.commodity[0]}, ${item.totalShipmentsItems.commodity[1]}`;
    else if (item.totalShipmentsItems?.commodity?.length)
      return item.totalShipmentsItems.commodity[0];
    else return "N/A";
  }

  public getAllCommodities(item) {
    return item.totalShipmentsItems?.commodity?.join(', ');
  }

  public onBtnBestOffer(job, offer) {
    if(this.isOnTabNotAcceptLoadTender) return // view only
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        closeOnSuccess: true,
        job: job,
        bestOffer: offer,
        cost: this.makeCost(offer.price),
        updateSuccess: resp => {
          this.onBtnRefresh()
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  private makeCost(price) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: price,
        qty: 1,
        total: price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: price,
      usdConversion: 0
    }
  }

  async assignCarrier(job, offer) {
    const params = {
      jobId: job._id,
      carrierId: offer.carrierId,
      cost: this.makeCost(offer.price)
    };
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).toPromise().catch(err => {
      this.showErr(err);
      console.log(" error: ", err);
    });

    if (resp) {
      this.onBtnRefresh();
      this.showDialog(`Carrier has been assigned successfully.<br /><br />
        <a href="${this.gotoDispatch(job)}" target="_blank">Go to dispatch</a>
      `);
    }
  }

  gotoDispatch(job) {
    return `${Const.routeAdminDispatchList}/${job._id}`
  }

  SplitTime(numberOfHours = 0) {
    var days = Math.floor(numberOfHours / 24);
    var remainder = numberOfHours % 24;
    var hours = Math.floor(remainder);
    var minutes = Math.floor(60 * (remainder - hours));
    return ({ days, hours, minutes })
  }

  getTimeToPickup(item) {
    let pickupTime = item?.pickDt?.time
    if (!pickupTime) return 'N/A'
    let duration = new Date(pickupTime).getTime() - new Date().getTime();
    const isNegative = duration < 0;
    duration = Math.abs(duration);
    let data = this.SplitTime(duration / (1000 * 60 * 60))
    let s = ''
    if (data.days) {
      s = `${data.days ?? 0} days, `
    }
    if (data.hours) {
      s += `${data.hours ?? 0} hours, `
    }
    if (data.minutes) {
      s += `${data.minutes ?? 0} minutes`
    }
    if (isNegative) s = `-${s}`;
    return s
  }

  onSearch(data = {}) {
    let tab = this.getTabData(this.tabIndex)
    const q: any = { bidStage: tab?.key, page: 1 }
    const search = this.queryParams.search ?? '';
    if (search) {
      q.search = search;
    }
    q['filter'] = JSON.stringify({ bidStage: tab?.key, ...data })
    this.routeWithQueryUrl(q, true);
  }

  public currentFilterDesc;

  onOpenSearch() {
    let filterObject = this.queryParams.filter ? JSON.parse(this.queryParams.filter) : {};
    DialogService.openFormDialog1(FilterModal, {
      nzComponentParams: {
        dataForm: filterObject,
        // data: item?.datRate?.cost_update?.cost,
        // closeOnSuccess: true,
        onSave: (data: FormDataFilter, previewInfo: string[]) => {
          this.currentFilterDesc = previewInfo.length > 0 ? previewInfo.join('\n') : '';
          this.onSearch(data);
        },
        currentTap: this.tabIndex,
      },
      nzClassName: "send-message-form modal-xl",
    });
  }
  public get isOnTabBidNotSent(): Boolean {
    return this.tabIndex == 0;
  }

  public get isOnTabBidSent(): Boolean {
    return this.tabIndex == 1;
  }

  public get isOnTabBidReceived(): Boolean {
    return this.tabIndex == 2;
  }

  public get isOnTabAssigned(): Boolean {
    return this.tabIndex == 3;
  }

  public get isOnTabNotAcceptLoadTender(): Boolean {
    return this.tabIndex == 4;
  }

  public get isOnTabAll(): Boolean {
    return this.tabIndex == 5;
  }

  public onBtnCarrierSalesRep(item: any) {
    const carrierSalesRepId = item?.carrierSalesRep?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: item.id,
        carrierSalesRepId: carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.refresh();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  public copyPublicLink(item: any) {
    const lastBid = Utils.cloneObject(item.carrierBids || []).pop();
    const bidId = lastBid?.id;
    const url = `${environment.carrierWebUrl}/public/load-board/${bidId}`;
    Utils.copyTextToClipboard(url, e => {
      if (e) {
        this.showErr('Cannot copy public link.');
      } else {
        this.showSuccess(`Public link was copied successfully. ${url}`);
      }
    });
  }

  onBtnCreateManualLoad() {
    DialogService.openFormDialog1(CreateManualLoadContainer, {
      nzClosable: false,
      nzMaskClosable: false,
      nzClassName: 'modal-fullscreen modal-no-padding',
      nzComponentParams: {
        onSubmitSucceeded: (resp) => {
          this.showDialogCreateManualLoadDone(resp.data);
          this.onBtnRefresh();
        }
      }
    });
  }

  @ViewChild('tplCreateManualLoadDone') tplCreateManualLoadDone: TemplateRef<any>;
  private dlgCreateManualLoadDone: NzModalRef;
  public dlgManualLoadDoneHyperLink;

  private showDialogCreateManualLoadDone(job) {
    this.dlgManualLoadDoneHyperLink = BizUtil.createHyperLinkForJob(job);
    this.dlgCreateManualLoadDone = this.modalService.create({
      nzContent: this.tplCreateManualLoadDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-manual-load-done'
    });
  }

  closeDialogCreateManualLoadDone() {
    this.dlgCreateManualLoadDone?.close();
  }

  get showAcceptedRate(){
    return this.isOnTabAssigned || this.isOnTabAll || this.isOnTabBidReceived || this.isOnTabNotAcceptLoadTender;
  }

  isMarketPlaceLoad(item) {
    return item.isGhostLoad && item.source == WarpConst.JobSources.marketplace;
  }
}
