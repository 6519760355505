import { Component, EventEmitter, Output } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { startOfDay, endOfDay } from 'date-fns'
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";

@Component({
  selector: '[filter-revenue]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    '../../../../styles/form-v2.scss'
  ]
})
export class ReportRevenueFilter extends BaseFormItem {
  @Output() getdata: EventEmitter<any> = new EventEmitter<any>();

  protected formGroupDeclaration: FormGroupDeclaration = {
    tz: {label: 'Time zone', required: true},
    fromDropDate: {label: 'From date', type: 'date', required: true, getValue: this.startOfDay},
    toDropDate: {label: 'To date', type: 'date', required: true, getValue: this.endOfDay},
  };

  public listTimezones = DateUtil.listUsTimezones;
  
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    this.model = this.buildDefaultValue();
    if (params.filter) {
      let obj = JSON.parse(params.filter);
      for (let key of this.formInputKeys) {
        if (obj[key]) {
          this.model[key] = obj[key];
        }
      }
    }
    super.ngOnInit();
    this.loadData();
  }

  private buildDefaultValue() {
    return {
      tz: 'Hawaii',
      fromDropDate: DateUtil.get1MonthAgo(),
      toDropDate: new Date(),
    };
  }

  resetFilter() {
  }

  onBtnReload() {
    this.loadData();
  }

  loadData() {
    let condition = this.getFormData_JSON(true);
    this.routeWithQueryUrl(condition);
    this.getdata.emit(condition);
  }

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }

  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }

  onChange(value, key) {
    if (!this.formGroupError) {
      setTimeout(() => this.loadData(), 1); // phải kết thúc hàm onChange thì trong form mới có dữ liệu
    }
  }

  protected setEnableFormGroup(enabled: boolean): void {
    // do nothing
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let data: any = super.getFormData_JSON(isCreateNew);
    let timezone = DateUtil.mapTimezoneUS(data.tz);
    let fromDropDate = DateUtil.convertLocalTime(data.fromDropDate, timezone).toISOString();
    let toDropDate = DateUtil.convertLocalTime(data.toDropDate, timezone).toISOString();
    return {tz: data.tz, fromDropDate, toDropDate};
  }

  public isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let json = this.getFormData_JSON(true);
    let query: any = {
      fromDropDate: json.fromDropDate,
      toDropDate: json.toDropDate,
      download: 1,
    }
    this.api.postExport(`${Const.APIURI_JOBS}/export_revenue`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

}
